var ko = require("knockout");
var ApiRequest = require("../Core/ApiRequest");
var Browser = require("../Core/BrowserControl");
var ComponentEvent = require("../Core/ComponentEvent");
var Constants = require("../Core/Constants");
var Helpers = require("../Core/Helpers");
var LoginConstants = require("../LoginPage/LoginConstants");
var ClientTracingConstants = require("../Core/ClientTracingConstants");

var w = window;
var BrowserHelper = Browser.Helper;
var QueryString = Browser.QueryString;
var Cookies = Browser.Cookies;
var SessionIdp = LoginConstants.SessionIdp;
var StringHelpers = Helpers.String;
var KeyCode = Constants.KeyCode;

function TileViewModel(params)
{
    var _this = this;

    
    var _serverData = params.serverData;
    var _session = params.session;
    var _hasFocus = params.hasFocus;
    var _ariaDescribedBy = params.ariaDescribedBy;
    var _isLogoutRequest = params.isLogoutRequest;
    var _tileIndex = params.tileIndex;
    

    
    var _strings = _serverData.str;
    var _forgetUrl = _serverData.urlForget;
    var _logoutUrl = _serverData.urlLogout;
    var _userRoutingCookieConfig = _serverData.userRoutingCookieConfig;
    var _msaLogoutUrl = _serverData.urlMsaLogout;
    var _otherIdpForgetUrl = _serverData.urlOtherIdpForget;
    var _useForgetUserIframe = _serverData.fUseForgetUserIframe;
    var _hideTileMenu = _serverData.fHideTileMenu;
    

    
    var _menuClicked = false;
    var _forgetMeTimer = null;
    

    
    _this.onTileClick = ComponentEvent.create();
    _this.onForgetComplete = ComponentEvent.create();
    

    
    _this.menuOpen = ko.observable(false);
    _this.menuFocused = ko.observable(false).extend({ notify: "always" });
    _this.iFrameSrc = ko.observable();
    _this.forgetIFrameSrc = ko.observable();
    _this.forgetFocus = ko.observable(false);
    _this.signoutFocus = ko.observable(false);
    _this.signoutAndForgetFocus = ko.observable(false);

    _this.session = _session;
    _this.hasFocus = _hasFocus;
    _this.ariaDescribedBy = _ariaDescribedBy;
    _this.tileIndex = _tileIndex;
    _this.hideTileMenu = false;
    _this.unsafe_helpText = null;
    _this.unsafe_signedInText = null;
    

    
    _this.dispose = function ()
    {
        _removeEventListeners();
        clearTimeout(_forgetMeTimer);
    };

    _this.iFrame_onload = function ()
    {
        clearTimeout(_forgetMeTimer);
    };
    

    
    _this.menu_onClick = function ()
    {
        _menuClicked = true;
        _setMenuItemFocus();
        _this.menuOpen(true);
        _addEventListeners();
    };

    _this.tile_onClick = function ()
    {
        if (!_menuClicked)
        {
            _setUserRoutingCookie(_session.name);
            _this.onTileClick(_session);
        }
    };

    _this.forget_onClick = function ()
    {
        _setUserRoutingCookie(_session.name);

        var forgetUrl = _session.isMeControlSession ? _otherIdpForgetUrl : _forgetUrl;
        forgetUrl = QueryString.appendOrReplace(forgetUrl, "sessionId", encodeURIComponent(_session.id));

        if (_session.isMeControlSession || _useForgetUserIframe)
        {
            _this.forgetIFrameSrc(forgetUrl);
            _forgetMeTimer = setTimeout(_forget_onError, Constants.DefaultRequestTimeout);
        }
        else
        {
            var forgetRequest = new ApiRequest();
            forgetRequest.Get(
                {
                    url: forgetUrl,
                    eventId: ClientTracingConstants.EventIds.Api_Forget
                },
                Constants.ContentType.Json,
                _forget_onSuccess,
                _forget_onError,
                Constants.DefaultRequestTimeout);
        }
    };

    _this.signout_onClick = function ()
    {
        _setUserRoutingCookie(_session.name);

        _iframeLogout(false);
    };

    _this.signoutAndForget_onClick = function ()
    {
        _setUserRoutingCookie(_session.name);

        _iframeLogout(true);
    };
    

    
    function _body_onKeyDown(e)
    {
        e = e || w.event;
        if (e.code === "Escape" || e.keyCode === KeyCode.Escape)
        {
            _this.menuOpen(false);
            _this.menuFocused(true);
            _removeEventListeners();
        }
        else if (e.code === "Enter" || e.keyCode === KeyCode.Enter)
        {
            if (_menuClicked)
            {
                _menuClicked = false;
            }
            else
            {
                _this.menuOpen(false);
                _removeEventListeners();
            }
        }
        else if (e.code === "ArrowUp" || e.keyCode === KeyCode.ArrowUp)
        {
            if (_this.menuOpen())
            {
                if (_this.signoutAndForgetFocus() && _session.isSignedIn)
                {
                    _this.signoutFocus(true);
                }
                else if (_this.signoutFocus() && _session.isSignedIn)
                {
                    _this.signoutAndForgetFocus(true);
                }
            }
        }
        else if (e.code === "ArrowDown" || e.keyCode === KeyCode.ArrowDown)
        {
            if (_this.menuOpen())
            {
                if (_this.signoutFocus() && _session.isSignedIn)
                {
                    _this.signoutAndForgetFocus(true);
                }
                else if (_this.signoutAndForgetFocus() && _session.isSignedIn)
                {
                    _this.signoutFocus(true);
                }
            }
        }
        else if (e.code === "Tab" || e.keyCode === KeyCode.Tab)
        {
            _this.menuOpen(false);
            _removeEventListeners();
        }
    }

    function _body_onClick()
    {
        if (_menuClicked)
        {
            _menuClicked = false;
            return;
        }

        _this.menuOpen(false);
        _this.menuFocused(true);
        _removeEventListeners();
    }

    function _forget_onSuccess()
    {
        _this.onForgetComplete(true, _session);
    }

    function _forget_onError()
    {
        _this.onForgetComplete(false, _session);
    }

    function _iframeLogout(shouldForgetUser)
    {
        var frameSrc = _session.isMeControlSession ? _msaLogoutUrl : _logoutUrl;

        if (_session.id)
        {
            frameSrc = QueryString.appendOrReplace(frameSrc, "sessionId", encodeURIComponent(_session.id));
            frameSrc = QueryString.appendOrReplace(frameSrc, "shouldForgetUser", shouldForgetUser);
        }

        _this.iFrameSrc(frameSrc);
    }

    function _addEventListeners()
    {
        BrowserHelper.addEventListener(document.body, "click", _body_onClick);
        BrowserHelper.addEventListener(document.body, "keydown", _body_onKeyDown);
    }

    function _removeEventListeners()
    {
        BrowserHelper.removeEventListener(document.body, "click", _body_onClick);
        BrowserHelper.removeEventListener(document.body, "keydown", _body_onKeyDown);
    }

    function _setMenuItemFocus()
    {
        _this.forgetFocus(!_session.isSignedIn);
        _this.signoutFocus(_session.isSignedIn && !_session.isMeControlSession);
        _this.signoutAndForgetFocus(_session.isSignedIn && _session.isMeControlSession);
    }

    function _setUserRoutingCookie(username)
    {
        if (_userRoutingCookieConfig && Cookies.isCookieSafeValue(username))
        {
            Cookies.writeWithExpiration(
                _userRoutingCookieConfig.name,
                username,
                _userRoutingCookieConfig.secure,
                Cookies.getPersistDate(),
                _userRoutingCookieConfig.domain,
                _userRoutingCookieConfig.path);
        }
    }

    (function _initialize()
    {
        var unsafe_homeTenantName = BrowserHelper.htmlUnescape(_session.homeTenantName);

        _this.hideTileMenu = _hideTileMenu || _isLogoutRequest || _session.isWindowsSso || _session.isSamsungSso;
        _this.session.unsafe_fullName = BrowserHelper.htmlUnescape(_session.fullName);
        _this.session.unsafe_displayName = BrowserHelper.htmlUnescape(_session.displayName);
        _this.session.unsafe_name = BrowserHelper.htmlUnescape(_session.name);
        _this.unsafe_signedInText = unsafe_homeTenantName
            ? StringHelpers.format(_strings["TILE_STR_Active_Tenant"], unsafe_homeTenantName)
            : _strings["TILE_STR_Active"];

        if (_session.idp === SessionIdp.Msa)
        {
            _this.unsafe_helpText = StringHelpers.format(_strings["TILE_STR_MsaTileHelpText"], _session.unsafe_displayName);
        }
        else
        {
            _this.unsafe_helpText = StringHelpers.format(_strings["TILE_STR_AadTileHelpText"], _session.unsafe_displayName);
        }

        _setMenuItemFocus();
    })();
    
}

ko.components.register("tile-field",
    {
        viewModel: TileViewModel,
        template: require("html/LoginPage/Fields/Tile/TileFieldHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = TileViewModel;