var w = window;

__webpack_public_path__ = w.ServerData.urlCdn;

var ko = require("knockout");
var KnockoutExtensions = require("../Core/KnockoutExtensions");
var StringRepository = require("../Core/StringRepository");
var LogoutPageViewModel = require("./LogoutPageViewModel");

KnockoutExtensions.applyExtensions(ko);

ko.utils.registerEventHandler(w, "load",
    function ()
    {
        var serverData = w.ServerData;
        serverData.str = StringRepository.getStrings("str", serverData);
        serverData.html = StringRepository.getStrings("html", serverData);

        document.body.appendChild(document.createElement("div")).innerHTML = require("html/LogoutPage/LogoutPageHtml.html");
        ko.applyBindings(new LogoutPageViewModel(serverData));
    });