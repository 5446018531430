
var ko = require("knockout");
var JSON = require("JSON");
var BrandingHelpers = require("../Core/BrandingHelpers");
var Browser = require("../Core/BrowserControl");
var Constants = require("../Core/Constants");
var Helpers = require("../Core/Helpers");
var TileHelpers = require("../Core/TileHelpers");
var PaginationControlHelper = require("../Core/PaginationControlHelper");

var w = window;
var PaginatedState = Constants.PaginatedState;
var BrowserHelper = Browser.Helper;
var StringHelpers = Helpers.String;

module.exports = function (serverData)
{
    var _this = this;

    var _serverData = serverData;

    var _sessions = _serverData.arrSessions;
    var _urlMsaMeControl = _serverData.urlMsaMeControl;
    var _isConsumersTenant = _serverData.fIsConsumersTenant;
    var _backgroundImage = _serverData.iBackgroundImage;
    var _logoutState = _serverData.sLogoutState;
    var _isDebugTracingEnabled = _serverData.fIsDebugTracingEnabled;
    var _fixSignoutBrandingCiam = _serverData.fFixSignoutBrandingCiam;
    var _isCiamLogoutRequest = _serverData.fIsCiamUserFlowUx;
    var _staticTenantBranding = _serverData.staticTenantBranding;

    
    _this.initialViewId = PaginatedState.Tiles;
    _this.currentViewId = PaginatedState.Tiles;
    _this.initialSharedData = {};
    _this.agreementType = null;
    _this.unsafe_logoutState = null;
    _this.isDebugTracingEnabled = _isDebugTracingEnabled;
    _this.layoutTemplatesEnabled = _fixSignoutBrandingCiam;

    _this.forceSubmit = ko.observable(false);
    _this.postLogoutRedirectUriValid = ko.observable(false);
    _this.paginationControlMethods = ko.observable();
    _this.backgroundControlMethods = ko.observable();
    _this.footerMethods = ko.observable();
    _this.debugDetailsMethods = ko.observable();
    _this.useDefaultBackground = ko.observable(false);
    _this.isMeControlSession = ko.observable(false);
    _this.fadeInLightBox = ko.observable(false);
    _this.showDebugDetails = ko.observable(false);
    _this.bannerLogoUrl = ko.observable();
    _this.backgroundLogoUrl = ko.observable();
    _this.loadBannerLogo = ko.observable(false);
    _this.paginationControlHelper = null;

    
    _this.dispose = function ()
    {
        if (_urlMsaMeControl)
        {
            BrowserHelper.removeEventListener(w, "message", _window_onMessage);
        }
    };

    _this.view_onSubmitReady = function (isMeControlSession)
    {
        _this.isMeControlSession(isMeControlSession);
        _this.forceSubmit(true);
    };

    _this.view_onLoadView = function ()
    {
        return null;
    };

    _this.view_onShow = function ()
    {
        
        
        var tenantBranding = null;
        if (_fixSignoutBrandingCiam && _isCiamLogoutRequest)
        {
            tenantBranding = BrandingHelpers.loadTenantBranding(_staticTenantBranding);
        }

        var branding = BrandingHelpers.getPageBranding(tenantBranding, null, _backgroundImage);

        _this.backgroundControlMethods().updateBranding(branding);
        _this.useDefaultBackground(branding.useDefaultBackground);

        if (_fixSignoutBrandingCiam)
        {
            _this.bannerLogoUrl(branding.bannerLogoUrl);
            _this.loadBannerLogo(_this.paginationControlHelper.showLogo());

            BrandingHelpers.updateMergedBrandingObservables(_this, tenantBranding);

            if (__CUSTOMIZATION_LOADER_ENABLED__)
            {
                BrandingHelpers.createCustomizationLoader(_serverData, tenantBranding);
            }
        }
    };

    _this.view_onSetLightBoxFadeIn = function (fadeIn)
    {
        _this.fadeInLightBox(fadeIn);
    };

    _this.footer_agreementClick = function (agreementType)
    {
        _this.agreementType = agreementType;

        _this.paginationControlMethods().view_onSwitchView(PaginatedState.ViewAgreement);
    };

    _this.closeDebugDetails_onClick = function ()
    {
        _setDebugDetailsState(false);

        if (_this.footerMethods())
        {
            _this.footerMethods().setDebugDetailsState(false);
        }
    };

    _this.toggleDebugDetails_onClick = function ()
    {
        var toggledDebugDetailsState = !_this.showDebugDetails();

        _setDebugDetailsState(toggledDebugDetailsState, true);
    };

    _this.setDebugTracing_onClick = function ()
    {
        _this.isDebugTracingEnabled = !_this.isDebugTracingEnabled;
    };

    
    
    function _window_onMessage(response)
    {
        
        
        if (!StringHelpers.doOriginsMatch(_urlMsaMeControl, response.origin))
        {
            return;
        }

        if (response && response.data)
        {
            var data = null;
            try
            {
                data = JSON.parse(response.data);
            }
            catch (e)
            {
                return;
            }

            _this.postLogoutRedirectUriValid(!!data.postLogoutRedirectUriValid);

            if (_isConsumersTenant)
            {
                
                
                _this.view_onSubmitReady(true);
            }
            else if (data.userList && data.userList.length)
            {
                var meControlSessions = TileHelpers.parseMeControlSessions(data.userList);
                var meControlActiveSessions = ko.utils.arrayFilter(
                    meControlSessions,
                    function (session)
                    {
                        return (session.isSignedIn || session.isWindowsSso);
                    });

                _updateUserTiles(meControlActiveSessions);
            }
            else if (!_sessions.length)
            {
                
                _this.view_onSubmitReady(false);
            }
        }
    }

    function _updateUserTiles(newSessions)
    {
        var paginationControlMethods = _this.paginationControlMethods();
        var currentView = paginationControlMethods.getCurrentView();
        if (currentView && currentView.viewId === PaginatedState.Tiles)
        {
            
            currentView.viewInterface.addNewSessions(newSessions, true );
        }
        else
        {
            
            TileHelpers.mergeSessions(paginationControlMethods.getSharedDataItem("sessions"), newSessions, true );
            paginationControlMethods.view_onSwitchView(PaginatedState.Tiles);
        }
    }

    function _setDebugDetailsState(state, activateDebugDetailsFocus)
    {
        _this.showDebugDetails(state);

        if (state && activateDebugDetailsFocus)
        {
            _activateDebugDetailsFocus();
            return;
        }

        if (!state && _this.footerMethods())
        {
            _this.footerMethods().focusMoreInfoLink();
        }
    }

    function _activateDebugDetailsFocus()
    {
        if (_this.debugDetailsMethods())
        {
            _this.debugDetailsMethods().activateFocus();
        }
    }

    (function _initialize()
    {
        if (_fixSignoutBrandingCiam)
        {
            _this.paginationControlHelper = new PaginationControlHelper(_serverData, _this.paginationControlMethods, _this.backgroundLogoUrl);
            BrandingHelpers.createMergedBrandingObservables(_this);
        }

        _this.initialSharedData.sessions = _sessions || [];
        _this.unsafe_logoutState = BrowserHelper.htmlUnescape(_logoutState);

        if (_urlMsaMeControl)
        {
            BrowserHelper.addEventListener(w, "message", _window_onMessage);
        }
    })();
};