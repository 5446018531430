

var ko = require("knockout");
var Constants = require("./Constants");
var Browser = require("./BrowserControl");
var Helpers = require("./Helpers");
var GetCredentialTypeRequestHelper = require("./GetCredentialTypeHelpers");
var Promise = require("./Promise");
var PromiseHelpers = require("./PromiseHelpers");
var ComponentEvent = require("./ComponentEvent");
var ApiRequest = require("../Core/ApiRequest");
var LoginConstants = require("../LoginPage/LoginConstants");
var BrowserSso = require("./BrowserSso");
var Otc = require("./OtcRequestControl");
var KnockoutExtenders = require("./KnockoutExtenders");
var ClientTracingConstants = require("../Core/ClientTracingConstants");
var Host = null;


if (__IS_CXH_ENABLED__)
{
    Host = require("../LoginPage/Win10LoginHost");
}
else if (__IS_INCLUSIVE_OOBE__)
{
    Host = require("../LoginPage/InclusiveWin10LoginHost");
}

var w = window;
var BrowserHelper = Browser.Helper;
var QueryString = Browser.QueryString;
var PaginatedState = Constants.PaginatedState;
var BindProvider = Constants.BindProvider;
var ApiErrorCodes = Constants.ApiErrorCodes;
var ArrayHelpers = Helpers.Array;
var StringHelpers = Helpers.String;
var ObjectHelpers = Helpers.Object;
var SessionIdp = LoginConstants.SessionIdp;
var GctResultAction = GetCredentialTypeRequestHelper.GctResultAction;
var GctRequestHelperFlags = GetCredentialTypeRequestHelper.GctRequestHelperFlags;

KnockoutExtenders.applyExtenders(ko);


function LoginTilesView(params)
{
    var _this = this;

    var c_msaForgetMe = "msaForgetMe";

    var _selectedSession = null;
    var _currentRequestId = 0;
    var _gctRequestHelper = null;
    var _gctResultSharedData = {};
    var _gctResultSharedDataForGctShowErrorResult = {};

    
    var _serverData = params.serverData;
    var _serverError = params.serverError;
    var _isInitialView = params.isInitialView;
    var _sessions = params.sessions;
    var _flowTokenParam = params.flowToken;
    var _isLogoutRequest = params.isLogoutRequest;
    var _isTileRequestPending = params.isTileRequestPending;
    var _otherIdpRedirectUrl = params.otherIdpRedirectUrl;
    var _availableSignupCreds = params.availableSignupCreds || [];

    var _strings = _serverData.str;
    var _protocolRefreshUrl = _serverData.urlLogin;
    var _logoutUrl = _serverData.urlLogout;
    var _forgetUrl = _serverData.urlForget;
    var _msaLogoutUrl = _serverData.urlMsaLogout;
    var _otherIdpForgetUrl = _serverData.urlOtherIdpForget;
    var _otherIdpRedirectPostParams = _serverData.oUrlOtherIdpPostParams;
    var _urlPrivacyStatement = _serverData.urlHostedPrivacyLink;
    var _appName = _serverData.sRemoteConnectAppName;
    var _remoteClientIp = _serverData.sRemoteClientIp;
    var _remoteAppLocation = _serverData.sRemoteAppLocation;
    var _bindProvider = _serverData.iBindProvider;
    var _linkedInFedUrl = _serverData.urlLinkedInFed;
    var _gitHubFedUrl = _serverData.urlGitHubFed;
    var _appHrdUrl = _serverData.urlAppHrd;
    var _signupUrl = _serverData.urlSignUp;
    var _showSignupTile = _serverData.fShowSignupTile;
    var _upgradeRedirectWithUsernameUrl = _serverData.urlUpgradeRedirectWithUsernane;
    var _upgradeRedirectUrl = _serverData.urlUpgradeRedirect;
    var _signUpPostParams = _serverData.oSignUpPostParams;
    var _upgradeMigrationConfig = _serverData.oUpgradeMigrationConfig || {};
    var _appCobranding = _serverData.oAppCobranding;
    var _aadSignupUrl = _serverData.urlAadSignup;
    var _originalRequest = _serverData.sCtx;
    var _checkApiCanary = _serverData.fCheckApiCanary;
    var _getOneTimeCodeUrl = _serverData.urlGetOneTimeCode;
    var _showRemoteConnectLocationPage = _serverData.fShowRemoteConnectLocationPage;
    var _useRemoteConnectDescriptionStrings = _serverData.fUseRemoteConnectDescriptionStrings;

    var _flowToken = ko.observable(_flowTokenParam).extend({ flowTokenUpdate: _serverData });

    
    _this.onSwitchView = ComponentEvent.create();
    _this.onRedirect = ComponentEvent.create();
    _this.onSubmitReady = ComponentEvent.create();
    _this.onSetPendingRequest = ComponentEvent.create();

    
    _this.sessions = ko.observableArray();
    _this.error = ko.observable();
    _this.pendingRequestIndex = ko.observable();
    _this.isBackButtonVisible = ko.observable(false);
    _this.selectedSessionId = ko.observable();
    _this.isTileRequestPending = ko.observable(false);

    _this.isLogoutRequest = _isLogoutRequest;
    _this.pageTitle = null;
    _this.pageDescription = null;
    _this.otherTileText = null;
    _this.unsafe_signupTileText = null;

    
    _this.dispose = function ()
    {
        _cancelPendingRequest();
        BrowserHelper.removeEventListener(window, "message", _iFrame_onMessage);
        BrowserHelper.removeEventListener(window, "message", _iFrame_onMessage_Msa);
    };

    _this.saveSharedData = function (sharedData)
    {
        sharedData.flowToken = _flowToken();
        sharedData.showCredViewBrandingDesc = !!(_appCobranding && _appCobranding.showDescOnCredViews);

        
        sharedData.sessions = ko.toJS(_this.sessions);

        if (_selectedSession)
        {
            sharedData.username = _selectedSession.name;
            sharedData.displayName = _selectedSession.displayName;

            ko.utils.extend(sharedData, _gctResultSharedData);
        }
        else
        {
            sharedData.username = null;
            sharedData.displayName = null;
        }
    };

    _this.getState = function ()
    {
        return { gctRequestHelperState: _gctRequestHelper.getState() };
    };

    _this.restoreState = function (state)
    {
        if (state)
        {
            _gctRequestHelper.restoreState(state.gctRequestHelperState);
        }
    };

    _this.addNewSessions = function (newSessions, replaceOtherIdpSessions)
    {
        _this.isTileRequestPending(false);

        
        
        
        ArrayHelpers.forEach(
            newSessions,
            function (newSession)
            {
                var duplicateSessionIndex = _findDuplicateSessionIndex(newSession, _this.sessions);
                var newSessionObservable = ko.observable(newSession);

                if (duplicateSessionIndex === -1)
                {
                    if (newSession.isWindowsSso)
                    {
                        _this.sessions.unshift(newSessionObservable);
                    }
                    else
                    {
                        _this.sessions.push(newSessionObservable);
                    }
                }
                else if (newSession.isWindowsSso)
                {
                    _this.sessions.splice(duplicateSessionIndex, 1);
                    _this.sessions.unshift(newSessionObservable);
                }
                else if (replaceOtherIdpSessions)
                {
                    _this.sessions.splice(duplicateSessionIndex, 1);
                    _this.sessions.push(newSessionObservable);
                }
            });
    };

    
    _this.tile_onClick = function (session)
    {
        _cancelPendingRequest();

        var requestId = _currentRequestId;

        _selectedSession = session;
        _this.error(null);

        
        if (_isLogoutRequest)
        {
            _this.selectedSessionId(session.id);
            _this.onSubmitReady(session.isMeControlSession);
        }
        else if (!session.isSignedIn && _upgradeRedirectWithUsernameUrl)
        {
            
            _this.onRedirect(_upgradeRedirectWithUsernameUrl);
        }
        else if ((session.isSignedIn || session.isWindowsSso || session.isSamsungSso) && !session.isMeControlSession && session.id && !session.isMsaPrt)
        {
            _this.onRedirect(QueryString.appendOrReplace(_protocolRefreshUrl, "sessionid", session.id));
        }
        else if (session.isOtherIdp)
        {
            var unsafe_username = StringHelpers.trim(_selectedSession.displayName);

            
            var otherIdpRedirectUrl = QueryString.appendOrReplace(_otherIdpRedirectUrl, "username", encodeURIComponent(unsafe_username));
            otherIdpRedirectUrl = QueryString.appendOrReplace(otherIdpRedirectUrl, "login_hint", encodeURIComponent(unsafe_username));

            var otherIdpRedirectPostParams = _otherIdpRedirectPostParams ? ObjectHelpers.clone(_otherIdpRedirectPostParams) : null;

            if (otherIdpRedirectPostParams)
            {
                otherIdpRedirectPostParams.username = unsafe_username;
            }

            
            
            if (session.isMsaPrt)
            {
                otherIdpRedirectPostParams = otherIdpRedirectPostParams || {};
                otherIdpRedirectPostParams.ests_canary = session.estsCanary;
                otherIdpRedirectPostParams.ests_headers = session.estsHeaders;
            }

            _this.onRedirect(otherIdpRedirectUrl, otherIdpRedirectPostParams, true);
        }
        else if (session.isWindowsSso)
        {
            var browserSsoHelper = new BrowserSso(_serverData);

            _setPendingRequestForSession(session);

            PromiseHelpers.throwUnhandledExceptionOnRejection(
                browserSsoHelper.loginWindowsUserAsync(session.ssoLink)
                    .then(null,
                        function ()
                        {
                            
                            return null;
                        })
                    .then(
                        function (redirectUrl)
                        {
                            if (requestId !== _currentRequestId)
                            {
                                return;
                            }

                            _resetPendingRequest();

                            if (redirectUrl)
                            {
                                _this.onRedirect(redirectUrl);
                            }
                        }));
        }
        else
        {
            _setPendingRequestForSession(session);

            PromiseHelpers.throwUnhandledExceptionOnRejection(
                _gctRequestHelper.sendAsync(_otherIdpRedirectUrl, BrowserHelper.htmlUnescape(session.name), _flowToken()).then(
                    function (gctResult)
                    {
                        if (gctResult.flowToken)
                        {
                            _flowToken(gctResult.flowToken);
                        }

                        if (requestId !== _currentRequestId)
                        {
                            return;
                        }

                        _resetPendingRequest();

                        switch (gctResult.action)
                        {
                            case GctResultAction.ShowError:
                                _this.error(gctResult.error);
                                _gctResultSharedDataForGctShowErrorResult = ko.utils.extend(gctResult.sharedData, gctResult.viewParams || {});
                                break;

                            case GctResultAction.SwitchView:
                                _gctResultSharedData = ko.utils.extend(gctResult.sharedData, gctResult.viewParams || {});
                                _this.onSwitchView(gctResult.viewId);
                                break;

                            case GctResultAction.Redirect:
                                _this.onRedirect(
                                    {
                                        url: gctResult.redirectUrl,
                                        eventOptions:
                                            {
                                                eventId: gctResult.eventId
                                            }
                                    },
                                    gctResult.redirectPostParams,
                                    gctResult.isIdpRedirect);
                                break;
                        }
                    }));
        }
    };

    _this.tile_onForgetComplete = function (forgotUser, session)
    {
        if (forgotUser)
        {
            _this.error(null);
            _forgetSession(session.id);
        }
        else
        {
            _this.error(StringHelpers.format(_strings["TILE_STR_Forget_Error"], session.displayName));
        }
    };

    _this.otherTile_onClick = function ()
    {
        _selectedSession = null;

        if (_appHrdUrl)
        {
            
            
            _this.onRedirect(_appHrdUrl);
        }
        else if (_upgradeRedirectUrl)
        {
            
            _this.onRedirect(_upgradeRedirectUrl);
        }
        else
        {
            switch (_bindProvider)
            {
                case BindProvider.LinkedIn:
                    _this.onRedirect(_linkedInFedUrl);
                    break;
                case BindProvider.GitHub:
                    _this.onRedirect(_gitHubFedUrl);
                    break;
                default:
                    _this.onSwitchView(PaginatedState.Username);
                    break;
            }
        }
    };

    _this.signup_onClick = function ()
    {
        if (_upgradeRedirectWithUsernameUrl)
        {
            
            _this.onRedirect(_upgradeRedirectWithUsernameUrl);
        }
        else if (_signupUrl)
        {
            _this.onRedirect(_signupUrl, _signUpPostParams);
        }
        else
        {
            _this.onSwitchView(_availableSignupCreds.length > 0
                ? PaginatedState.SignupCredentialPicker
                : PaginatedState.SignupUsername);
        }
    };

    _this.aadSignup_onClick = function ()
    {
        _this.onRedirect(QueryString.appendOrReplace(_aadSignupUrl, "email", encodeURIComponent(_this.usernameTextbox.value())));
    };

    _this.sendOtcLink_onClick = function ()
    {
        PromiseHelpers.throwUnhandledExceptionOnRejection(
            _getOneTimeCodeAsync(Otc.Channel.EmailAddress).then(
                function (result)
                {
                    if (result.success)
                    {
                        _gctResultSharedData = _gctResultSharedDataForGctShowErrorResult;
                        _this.onSwitchView(PaginatedState.OneTimeCode);
                    }
                }));
    };

    _this.skip_onClick = function ()
    {
        Host.handleOnSkip(_serverData);
    };

    _this.privacy_onClick = function ()
    {
        if (_urlPrivacyStatement)
        {
            _this.onRedirect(_urlPrivacyStatement);
        }
        else
        {
            _this.onSwitchView(PaginatedState.ViewAgreement);
        }
    };

    _this.secondaryButton_onClick = function ()
    {
        _this.onSwitchView(PaginatedState.Previous);
    };

    
    function _getOneTimeCodeAsync(channel)
    {
        return _getOneTimeCodeApiRequestAsync(channel)
            .then(_handleGetOneTimeCodeSuccess, _handleGetOneTimeCodeError);
    }

    function _getOneTimeCodeApiRequestAsync(channel)
    {
        return new Promise(
            function (resolve, reject)
            {
                _this.onSetPendingRequest(true);

                var postData =
                    {
                        OriginalRequest: _originalRequest,
                        FlowToken: _flowToken(),
                        Channel: channel
                    };

                var apiRequest = new ApiRequest({ checkApiCanary: _checkApiCanary });
                apiRequest.Json(
                    {
                        url: _getOneTimeCodeUrl,
                        eventId: ClientTracingConstants.EventIds.Api_GetOneTimeCode
                    },
                    postData,
                    resolve,
                    reject,
                    Constants.DefaultRequestTimeout);
            });
    }

    function _handleGetOneTimeCodeSuccess()
    {
        _this.onSetPendingRequest(false);

        return { success: true };
    }

    function _handleGetOneTimeCodeError(response)
    {
        _this.onSetPendingRequest(false);

        if (response && response.error)
        {
            switch (response.error.code)
            {
                case ApiErrorCodes.AuthFailure:
                    _this.usernameTextbox.error.setNonBlockingError(_strings["CT_PWD_STR_Error_FlowTokenExpired"]);
                    break;

                default:
                    _this.usernameTextbox.error.setNonBlockingError(_strings["CT_PWD_STR_Error_GetOneTimeCodeError"]);
                    break;
            }
        }

        return { success: false };
    }

    function _findDuplicateSessionIndex(newSession, sessions)
    {
        var sessionsArray = sessions();
        for (var i = 0; i < sessionsArray.length; i++)
        {
            var existingSession = sessionsArray[i]();
            if (existingSession.name === newSession.name && existingSession.idp === newSession.idp)
            {
                return i;
            }
        }

        return -1;
    }

    function _setPendingRequestForSession(session)
    {
        _this.pendingRequestIndex(
            ArrayHelpers.findIndex(
                _this.sessions(),
                function (observableSession)
                {
                    return session === observableSession();
                }));

        _this.onSetPendingRequest(true);
    }

    function _cancelPendingRequest()
    {
        _currentRequestId++;
        _resetPendingRequest();
    }

    function _resetPendingRequest()
    {
        _this.pendingRequestIndex(null);
        _this.onSetPendingRequest(false);
    }

    function _iFrame_onMessage_Msa(event)
    {
        if (!event || (!StringHelpers.doOriginsMatch(_msaLogoutUrl, event.origin) && !StringHelpers.doOriginsMatch(_otherIdpForgetUrl, event.origin)))
        {
            return;
        }

        var eventData = event.data;

        
        if (eventData === "signedout")
        {
            _this.sessions.remove(
                function (item)
                {
                    return item().idp === SessionIdp.Msa;
                });

            
            
            if (!_this.isTileRequestPending() && !_this.sessions().length)
            {
                _this.onSwitchView(PaginatedState.Username, true);
            }
        }
        else if (eventData && eventData.messageType === c_msaForgetMe && eventData.sessionId)
        {
            _this.tile_onForgetComplete(eventData.forgotUser, eventData.sessionId);
        }
        else
        {
            
            _signoutSession(eventData);
        }
    }

    function _iFrame_onMessage(event)
    {
        
        
        if (!event || (!StringHelpers.doOriginsMatch(_logoutUrl, event.origin) && !StringHelpers.doOriginsMatch(_forgetUrl, event.origin)))
        {
            return;
        }

        var eventData = event.data;

        if (eventData && eventData.messageType === c_msaForgetMe && eventData.sessionId)
        {
            _this.tile_onForgetComplete(eventData.forgotUser, eventData.sessionId);
        }
        else
        {
            
            _signoutSession(eventData);
        }
    }

    function _signoutSession(data)
    {
        if (!data || !data.sessionId)
        {
            return;
        }

        var session = ko.utils.arrayFirst(
            _this.sessions(),
            function (observableSession)
            {
                return data.sessionId === observableSession().id;
            });

        var sessionToUpdate = session();
        if (sessionToUpdate)
        {
            if (data.signoutStatus)
            {
                _this.error(null);

                if (data.forgotUser)
                {
                    _forgetSession(sessionToUpdate.id);
                }
                else
                {
                    sessionToUpdate.isSignedIn = false;
                    session(sessionToUpdate);
                }
            }
            else
            {
                _this.error(StringHelpers.format(_strings["TILE_STR_Signout_Error"], sessionToUpdate.displayName));
            }
        }
    }

    function _forgetSession(sessionId)
    {
        
        
        _this.sessions.remove(
            function (item)
            {
                return item().id === sessionId;
            });

        
        
        if (!_this.isTileRequestPending() && !_this.sessions().length)
        {
            _this.onSwitchView(PaginatedState.Username, true);
        }
    }

    (function _initialize()
    {
        var pageDescription = null;
        var pageTitle = _strings["TILE_STR_Header"];
        var otherTileText = _strings["TILE_STR_UseAnother"];

        _gctRequestHelper = new GetCredentialTypeRequestHelper(_serverData, GctRequestHelperFlags.CheckCurrentIdpOnly);

        _this.isTileRequestPending(_isTileRequestPending);

        if (_upgradeMigrationConfig.upgradeMigrationUXID)
        {
            pageTitle = _strings["TILE_STR_UpgradeHeader"];
            pageDescription = _strings["TILE_STR_UpgradeDescription"];

            if (_upgradeMigrationConfig.allowSignupName)
            {
                _this.unsafe_signupTileText = StringHelpers.format(_strings["TILE_STR_Signup"],
                    BrowserHelper.htmlUnescape(_upgradeMigrationConfig.allowSignupName));
            }
        }
        else if (_showSignupTile)
        {
            _this.unsafe_signupTileText = _strings["TILE_STR_Signup"];
        }
        else if (_strings["WF_STR_Default_Desc"] && !_showRemoteConnectLocationPage)
        {
            if (_useRemoteConnectDescriptionStrings)
            {
                pageDescription = StringHelpers.format(_strings["WF_STR_Default_Desc"], _remoteClientIp || _remoteAppLocation);
            }
            else
            {
                
                pageDescription = StringHelpers.format(_strings["WF_STR_Default_Desc"], _appName, _remoteClientIp || _remoteAppLocation);
            }
        }
        else if (_isLogoutRequest)
        {
            pageDescription = _strings["TILE_STR_Description"];
        }
        else
        {
            switch (_bindProvider)
            {
                case BindProvider.LinkedIn:
                    pageDescription = _strings["TILE_STR_Desc_LinkedIn"];
                    otherTileText = _strings["TILE_STR_UseAnother_LinkedIn"];
                    break;
                case BindProvider.GitHub:
                    pageDescription = _strings["TILE_STR_Desc_GitHub"];
                    otherTileText = _strings["TILE_STR_UseAnother_GitHub"];
                    break;
            }
        }

        _this.pageTitle = pageTitle;
        _this.pageDescription = pageDescription;
        _this.otherTileText = otherTileText;

        ArrayHelpers.forEach(
            _sessions,
            function (session)
            {
                _this.sessions.push(ko.observable(session));

                if (session.matchesLoginHint)
                {
                    _selectedSession = session;
                }
            });

        if (_serverError)
        {
            _this.error(_serverError);
            _serverError = null;
        }

        
        
        if (_isInitialView && Host && Host.isBackButtonSupportedOnInitialView)
        {
            Host.isBackButtonSupportedOnInitialView(_serverData,
                function (isSupported)
                {
                    _this.isBackButtonVisible(isSupported);
                });
        }
        else
        {
            _this.isBackButtonVisible(true);
        }

        BrowserHelper.addEventListener(window, "message", _iFrame_onMessage);
        BrowserHelper.addEventListener(window, "message", _iFrame_onMessage_Msa);
    })();
}

ko.components.register("tiles-view",
    {
        viewModel: LoginTilesView,
        template: require("html/Shared/Views/TilesViewHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = LoginTilesView;